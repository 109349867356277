import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function PrivacyPolicy(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <h1 className={classes.title}>
            TERMINOS DE USO Y CONDICIONES GENERALES PARA USUARIOS
          </h1>
          <h2 className={classes.title}>1. Antecedentes</h2>
          <p className={classes.description}>
            TU TAXI SEGURO, Nombre commercial registrado por Holguer Ramito
            Cajamarca Guevara, con RUC: 0602723074001 (En adelante TU TAXI).
            Legalmente autorizado para prestar el servicio de transporte en taxi
            dentro de la República del Ecuador, mediante unidades de taxi y
            aplicativo móvil para la interconexión de usuarios con sus unidades
            de taxi registradas en su Plataforma. Los presentes términos y
            condiciones deberán leerse detenidamente por los usuarios para
            acceder a los servicios ofertados por nuestra plataforma
            tecnológica, de este modo usted acuerda vincularse jurídicamente por
            estas condiciones, que establecen una relación contractual entre el
            usuario y TU TAXI
          </p>
          <h2 className={classes.title}>2. Objeto</h2>
          <p className={classes.description}>
            Las presentes Condiciones Generales de Uso regulan el uso de la
            aplicación informática TU TAXI (en adelante, la “Aplicación”), del
            sitio web www.tutaxiseguro.com (en adelante, el “Sitio Web”), ya sea
            con su actual nombre o con cualquier otra denominación con la que
            pueda figurar en el futuro, de TU TAXI QUITO a nombre de Holguer
            Ramiro Cajamarca Guevara, domiciliado en Ramón Pacheco Oe13-131 y
            Juan Obando, Distrito Metropolitano de Quito, Ecuador. Los usuarios
            que residan o se encuentren domiciliados en cualquier otro lugar y
            que decidan acceder y/o utilizar esta Aplicación lo harán bajo su
            propia responsabilidad y riesgo y deberán asegurarse de que tal
            acceso y/o utilización cumple con la legislación local aplicable. Al
            acceder y utilizar esta Aplicación y Sitio Web y los servicios de TU
            TAXI el usuario del aplicativo (“Usuario”) reconoce que ha leído y
            aceptado estas Condiciones Generales de Uso y se compromete a
            cumplir con todos sus términos y condiciones. Asimismo, el Usuario
            reconoce y acepta que el acceso y uso de esta Aplicación y/o Sitio
            Web estará sujeto a las Condiciones Generales de Uso que se
            encuentren en vigor en el momento en que acceda a la misma.
          </p>
          <p className={classes.description}>
            TU TAXI se reserva el derecho de modificar en cualquier momento las
            presentes Condiciones Generales de Uso, así como cualesquiera otras
            condiciones generales o particulares que resulten de aplicación. El
            Usuario podrá notificar a TU TAXI de manera escrita que no acepta
            cualquier eventual modificación de las presentes Condiciones
            Generales. Asimismo, TU TAXI se reserva el derecho de suspender,
            interrumpir o dejar de operar la Aplicación y/o el Sitio Web en
            cualquier momento.
          </p>
          <h2 className={classes.title}>3. Contenidos</h2>
          <p className={classes.description}>
            A través de la Aplicación, TU TAXI provee al Usuario un servicio de
            comunicación que permite la reserva de taxi para transporte personal
            o de bienes materiales. La Aplicación y/o Sitio Web, permite al
            Usuario el acceso a diversos contenidos, información y datos
            proporcionados por TU TAXI en relación con el Servicio (en adelante,
            los “Contenidos”). TU TAXI se reserva el derecho a modificar en
            cualquier momento las modalidades del Servicio, la presentación, la
            configuración y ubicación de la Aplicación y/o el Sitio Web, así
            como las correspondientes condiciones de acceso y uso. TU TAXI no
            garantiza que los Contenidos proporcionados a través de la
            Aplicación y/o Sitio Web serán en todo caso correctos, completos o
            actualizados o libres de errores. TU TAXI actúa en calidad
            intermediario en la prestación de servicios de taxi al Usuario. Será
            responsabilidad exclusiva de los taxistas o conductores la
            prestación de los servicios contratados a través de la Aplicación y
            el Servicio desarrollado por TU TAXI. TU TAXI únicamente pone a
            disposición del Usuario una herramienta para reservar los servicios
            prestados por los taxistas registrados en la Plataforma. El Usuario
            exonera a TU TAXI de cualquier responsabilidad derivada del servicio
            prestado por los taxistas proveedores del servicio. El Usuario
            reconoce que TU TAXI actúa como intermediario entre el Usuario y
            prestadores de servicios de taxi.
          </p>
          <h2 className={classes.title}>4. Acceso y uso</h2>
          <p className={classes.description}>
            Para usar los servicios de TU TAXI, el Usuario deberá registrarse y
            mantener una cuenta activa, el acceso a la Aplicación estará
            limitado a mayores de 18 años, quienes para poder registrarse
            deberán proporcionar a TU TAXI determinada información personal,
            como sus nombre y apellidos, dirección, número de cédula, número de
            teléfono. Cualquier error o falta de envío de esta información por
            parte del Usuario, no permitirá el acceso y uso de la plataforma
            tecnológica, así como también la terminación de este contrato. El
            Usuario (i) no podrá autorizar a terceros a utilizar su cuenta, ni
            podrá permitir a personas menores de 18 años el acceso a la
            Aplicación a través de su cuenta; ni podrá permitir que personas
            menores de 18 años reciban servicios a través de la Aplicación, a
            menos que los menores vayan acompañados por el Usuario titular de la
            cuenta. El usuario reconoce y acepta que el acceso y uso de la
            Aplicación y/o Sitio Web tiene lugar libre y conscientemente, bajo
            su exclusiva responsabilidad. TU TAXI no será en ningún caso
            responsable del uso que el Usuario y/o terceros pudieran hacer de la
            Aplicación y/o Sitio Web ni de los daños y perjuicios que pudieran
            derivarse del mismo. El Usuario se compromete a hacer un uso
            adecuado y lícito de la Aplicación y/o Sitio Web de conformidad con
            la legislación aplicable y las presentes Condiciones Generales de
            Uso, la moral y las buenas costumbres generalmente aceptadas, así
            como el orden público. El Usuario deberá abstenerse de (i) hacer un
            uso no autorizado o fraudulento de la Aplicación y/o Sitio Web; (ii)
            acceder o intentar acceder a recursos restringidos de la Aplicación
            y/o Sitio Web; (iii) utilizar la Aplicación y/o Sitio Web con fines
            o efectos ilícitos, ilegales, contrarios a lo establecido en las
            presentes Condiciones Generales de Uso, lesivos de los derechos e
            intereses de terceros, o que de cualquier forma puedan dañar,
            inutilizar o sobrecargar o impedir la normal utilización o disfrute
            de la Aplicación y/o Sitio Web; (iv) provocar daños en la Aplicación
            y/o Sitio Web o en los sistemas de sus proveedores o de terceros;
            (v) introducir o difundir virus informáticos o cualesquiera otros
            sistemas físicos o lógicos que sean susceptibles de provocar daños
            en los sistemas de TU TAXI, de sus proveedores o de terceros, (vi)
            intentar acceder, utilizar y/o manipular los datos de TU TAXI,
            terceros proveedores y otros usuarios; (vii) reproducir, copiar,
            distribuir, transformar o modificar los Contenidos, permitir el
            acceso a terceros a través de cualquier modalidad de comunicación
            pública, a menos que se cuente con la autorización del titular de
            los correspondientes derechos o ello esté legalmente permitido;
            (viii) obtener o intentar obtener los contenidos empleando para ello
            medios o procedimientos distintos de los que, según los casos, se
            hayan puesto a su disposición a este efecto. El Usuario únicamente
            podrá acceder a la Aplicación y/o Sitio Web a través de los medios
            autorizados. TU TAXI no será responsable en caso de que el Usuario
            no disponga de un dispositivo compatible o haya descargado una
            versión de la Aplicación incompatible con su dispositivo.
          </p>
          <h2 className={classes.title}>
            5. Condiciones adicionales para el acceso y uso
          </h2>
          <p className={classes.description}>
            El acceso a la Aplicación estará sujeto en todo caso al previo
            registro del Usuario mediante la cumplimentación del correspondiente
            formulario (“Regístrate”), que será previamente facilitado al
            Usuario. TU TAXI se reserva el derecho de aceptar o rechazar
            libremente la solicitud de registro por parte del Usuario. Los datos
            introducidos por el Usuario en el Formulario de Registro deberán ser
            exactos, actuales y veraces en todo momento. El acceso y uso de la
            Aplicación o del área específica del Sitio Web estará sujeta una
            contraseña provista por el Usuario&nbsp; al rellenar el Formulario
            de Registro y el acceso se reputará realizado por dicho Usuario,
            quien responderá en todo caso de tal acceso y uso. El Usuario será
            responsable en todo momento de la custodia de su contraseña,
            asumiendo, en consecuencia, cualesquiera daños y perjuicios que
            pudieran derivarse de su uso indebido, así como de la cesión,
            revelación o extravío de la misma. En caso de olvido de la
            contraseña o cualquier otra circunstancia que suponga un riesgo de
            acceso y/o utilización por parte de terceros no autorizados, el
            Usuario lo comunicará inmediatamente a TU TAXI a fin de que éste
            proceda inmediatamente al bloqueo y sustitución de la misma. En todo
            caso, cualesquiera operaciones realizadas antes de dicha
            comunicación se reputarán efectuadas por el Usuario, quien será
            responsable y pagará los gastos y/o daños que se deriven de
            cualquier acceso y/o uso no autorizado que se realice con
            anterioridad a dicha comunicación. Los Usuarios autorizan
            expresamente a TU TAXI a entregar su información personal frente a
            cualquier requerimiento de autoridad pública.
          </p>
          <h2 className={classes.title}>
            6. Condiciones de pago y facturación
          </h2>
          <p className={classes.description}>
            Las tarifas cobradas no serán reembolsables. Las tarifas, sus
            actualizaciones, y condiciones de uso estarán disponibles en todo
            momento en la Aplicación y/o Sitio Web y están sujetas a posibles
            modificaciones. Se recomienda al Usuario que acceda periódicamente a
            la Aplicación y/o el Sitio Web para conocer las tarifas aplicables
            en cada momento. En el momento en que el usuario solicite el
            servicio a través de la Aplicación, podrá consultar una estimación
            de dicho costo junto con el precio estimado del trayecto, que se
            mostrará siempre y cuando el Usuarios haya incluido el punto de
            destino. TU TAXI se reserva el derecho a modificar, ampliar,
            reducir, establecer y/o eliminar cualquier modalidad de pago en
            cualquier momento según estime conveniente para el correcto
            funcionamiento del Servicio. Las tarifas aplicables al Servicio
            serán cobradas al Usuario al finalizar el desplazamiento, dichas
            tarifas serán cobradas directamente por el taxista mediante pago en
            efectivo al finalizar el trayecto.&nbsp;
          </p>
          <h2 className={classes.title}>
            7. Derechos de propiedad intelectual e industrial
          </h2>
          <p className={classes.description}>
            El Usuario reconoce y acepta que todos los derechos de propiedad
            intelectual e industrial sobre los contenidos y/o cualesquiera otros
            elementos insertados en la Aplicación y/o Sitio Web (incluyendo, sin
            limitación, marcas, logotipos, nombres comerciales, textos,
            imágenes, gráficos, diseños, sonidos, bases de datos, software,
            diagramas de flujo, presentación, audio y vídeo), pertenecen a TU
            TAXI. TU TAXI autoriza al Usuario a utilizar, visualizar, imprimir,
            descargar y almacenar los contenidos y/o los elementos insertados en
            la Aplicación y/o Sitio Web exclusivamente para su uso personal,
            privado y no lucrativo, absteniéndose de realizar sobre los mismos
            cualquier acto de descompilación, ingeniería inversa, modificación,
            divulgación o suministro. Cualquier otro uso o explotación de
            cualesquiera contenidos y/u otros elementos insertados en la
            Aplicación y/o Sitio Web distinto de los aquí expresamente previstos
            estará sujeto a la autorización previa de TU TAXI. El Usuario no
            está autorizado a utilizar cualquier marca registrada, derechos de
            autor u otro derecho de propiedad intelectual de cualquier parte
            autorizada o en redes sociales; así como tampoco podrá reproducir,
            modificar, realizar obras derivadas de los servicios, distribuir,
            licenciar, arrendar, revender, transferir, exhibir públicamente,
            transmitir, retransmitir o explotar de otras formas los servicios a
            los cuales tiene acceso a través de la plataforma de TU TAXI. Por
            último, el usuario no podrá dañar la imagen o cualquier aspecto
            relacionado a los servicios mencionados. El Usuario no podrá
            utilizar la Aplicación para fines distintos a los establecidos en
            este documento.
          </p>
          <h2 className={classes.title}>8. Protección de datos</h2>
          <p className={classes.description}>
            Los datos personales (en lo sucesivo, los Datos) que el Usuario
            proporciona por medio del Formulario de Registro se integrarán en un
            fichero de datos personales del que es responsabilidad de TU TAXI.
            Para más información consultar la Política de Privacidad al final de
            este documento.
          </p>
          <h2 className={classes.title}>
            9. Exclusión de garantías y responsabilidad
          </h2>
          <p className={classes.description}>
            TU TAXI no garantiza la disponibilidad y continuidad del
            funcionamiento de la Aplicación y/o Sitio Web. En consecuencia, TU
            TAXI no será en ningún caso responsable por cualesquiera daños y
            perjuicios que puedan derivarse de (i) la falta de disponibilidad o
            accesibilidad a la Aplicación y/o Sitio Web; (ii) la interrupción en
            el funcionamiento de la Aplicación y/o Sitio Web o fallos
            informáticos, averías telefónicas, desconexiones, retrasos o
            bloqueos causados por deficiencias o sobrecargas en las líneas
            telefónicas, centros de datos, en el sistema de Internet o en otros
            sistemas electrónicos, producidos en el curso de su funcionamiento;
            y (iii) otros daños que puedan ser causados por terceros mediante
            intromisiones no autorizadas ajenas al control de TU TAXI. Los
            Usuarios deberán retirar del vehículo todos sus efectos personales
            una vez finalizado el trayecto. TU TAXI no se hará responsable de la
            pérdida o extravío de objetos personales en los vehículos, si bien
            les facilitará cualquier ayuda o información que pudiera agilizar su
            recuperación, con el objetivo de agilizar dicha recuperación, TU
            TAXI pone a disposición del Usuario el número de contacto 02 228
            2828 / 099 890 8051, en el que el Usuario podrá comunicar a TU TAXI
            la pérdida de cualquier objeto durante un trayecto. TU TAXI no
            garantiza la ausencia de virus ni de otros elementos en la
            Aplicación y/o Sitio Web introducidos por terceros ajenos a TU TAXI
            que puedan producir alteraciones en los sistemas físicos o lógicos
            del Usuario o en los documentos electrónicos y ficheros almacenados
            en sus sistemas. En consecuencia, TU TAXI no será en ningún caso
            responsable de cualesquiera daños y perjuicios de toda naturaleza
            que pudieran derivarse de la presencia de virus u otros elementos
            que puedan producir alteraciones en los sistemas físicos o lógicos,
            documentos electrónicos o ficheros del Usuario. TU TAXI adopta
            diversas medidas de protección para proteger la Aplicación y/o Sitio
            Web y los contenidos contra ataques informáticos de terceros. No
            obstante, TU TAXI no garantiza que terceros no autorizados puedan
            conocer las condiciones, características y circunstancias en las
            cuales el Usuario accede a la Aplicación y/o Sitio Web. En
            consecuencia, TU TAXI no será en ningún caso responsable de los
            daños y perjuicios que pudieran derivarse de dicho acceso no
            autorizado. Con la suscripción de las presentes Condiciones
            Generales de Uso, el Usuario declara que mantendrá indemne frente a
            cualquier reclamación a TU TAXI, su propietario, socios, empleados,
            abogados y agentes derivadas del (i) incumplimiento por parte del
            Usuario de cualquier disposición contenida las presentes Condiciones
            Generales de Uso o de cualquier ley o regulación aplicable a las
            mismas, (ii) incumplimiento o violación de los derechos de terceros
            incluyendo, a título meramente enunciativo, los terceros
            transportistas, los taxistas, otros conductores y peatones; (iii)
            cualquier daño o perjuicio causado por el Usuario y/o sus
            acompañantes en el uso del Servicio a un tercero y (iv)
            incumplimiento del uso permitido de la Aplicación y/o Sitio Web. La
            prestación final del servicio contratado a través de la Aplicación
            quedará a discreción del tercero proveedor de dicho servicio según
            proceda. TU TAXI proveerá al Usuario del servicio de interconexión,
            siendo los servicios contratados a través de la Aplicación ajenos a
            la Compañía. El Usuario renuncia y exonera a TU TAXI de cualesquiera
            obligaciones, reclamaciones o daños surgidos como consecuencia de la
            relación con el proveedor de servicios contratado a través de la
            Aplicación. El tercero transportista podrá aceptar o rechazar la
            asignación del trayecto en cualquier momento. En el caso de que el
            rechazo del viaje se produzca cuando ya se hubiese notificado al
            Usuario la identidad del Conductor asignado, TU TAXI ofrecerá dicho
            trayecto a otros transportistas para que el trayecto sea prestado al
            Usuario correctamente en tiempo y en forma. TU TAXI no se
            constituirá como parte en cualesquiera disputas entre el Usuario y
            el proveedor contratado a través de la Aplicación. Toda
            responsabilidad u obligación nacida en virtud de la relación entre
            el Usuario y el proveedor contratado a través de la Aplicación, ya
            sea vía software o prestación de servicios, descansa únicamente en
            el Usuario, con todas las implicaciones que ello conlleva. Por
            consiguiente, entiende el Usuario que (i) con el uso de la
            Aplicación y/o Sitio Web y por consiguiente del servicio contratado
            a través de la Aplicación, puede exponerse a un servicio ajeno a la
            compañía que podría ser potencialmente peligroso, dañino,
            perjudicial para menores dados los riesgos inherentes a la propia
            actividad del servicio contratado, (ii) y que dicha actividad la
            realiza bajo su propio riesgo y responsabilidad.
          </p>
          <h2 className={classes.title}>10. Notificaciones</h2>
          <p className={classes.description}>
            TU TAXI podrá realizar las notificaciones oportunas a través de una
            notificación general en la Aplicación, a través de la dirección de
            correo electrónico facilitada por el Usuario en el Formulario de
            Registro, mediante SMS, o a través de correo ordinario a la
            dirección facilitada por el Usuario en el Formulario de Registro. El
            Usuario, a su vez podrá cursar notificaciones a TU TAXI a través de
            los números de contacto 02 228 2828 / 099 890 8051.
          </p>
          <h2 className={classes.title}>11. Cesión</h2>
          <p className={classes.description}>
            El Usuario no podrá ceder los derechos y obligaciones asumidos en
            las presentes Condiciones Generales de Uso sin el previo
            consentimiento escrito de TU TAXI. TU TAXI podrá ceder, sin
            necesidad de recabar el consentimiento previo del Usuario, las
            presentes Condiciones Generales de Uso a cualquier persona o entidad
            que le suceda en el ejercicio de su negocio por cualesquiera
            títulos.
          </p>
          <h2 className={classes.title}>12. Ley aplicable y Jurisdicción</h2>
          <p className={classes.description}>
            Las presentes Condiciones Generales de Uso, así como la relación
            entre TU TAXI y el Usuario, se regirán e interpretarán con arreglo
            la legislación de la República del Ecuador. Toda controversia o
            diferencia que surja de, relativa a, o que tenga relación con este
            contrato, será resuelta por un tribunal arbitral del Centro de
            Arbitraje y Mediación de la Cámara de Comercio de Quito, que se
            sujetará a lo dispuesto en la Ley de Arbitraje y Mediación, el
            Reglamento de dicho Centro y las siguientes normas: “ El tribunal
            estará integrado por un árbitro designado conforme el Reglamento del
            Centro; “ El tribunal decidirá en derecho; “ Para la ejecución de
            medidas cautelares, el tribunal arbitral está facultado para
            solicitar a los funcionarios públicos, judiciales, policiales y
            administrativos su cumplimiento sin que sea necesario el recurrir a
            juez ordinario alguno; “ El procedimiento arbitral será
            confidencial.
          </p>
          <h2 className={classes.title}>13. Protocolos sanitarios</h2>
          <p className={classes.description}>
            El Usuario se obliga a cumplir con todos los protocolos y normas
            sanitarias dispuestas por la OMS, MSP y acogerse y respetar las
            restricciones de movilidad y tránsito establecidas por autoridad
            competente.
          </p>
          <p className={classes.description}>&nbsp;</p>
          <h1 className={classes.title}>POLÍTICA DE PRIVACIDAD</h1>
          <p className={classes.description}>
            La presente Política de Privacidad establece los términos en que TU
            TAXI SEGURO usa y protege la información que es proporcionada por
            sus usuarios al momento de utilizar su aplicación móvil y/o sitio
            web. Esta compañía está comprometida con la seguridad de los datos
            de sus usuarios. Cuando le pedimos llenar los campos de información
            personal con la cual usted pueda ser identificado, lo hacemos
            asegurando que sólo se empleará de acuerdo con los términos de este
            documento. Sin embargo esta Política de Privacidad puede cambiar con
            el tiempo o ser actualizada por lo que le recomendamos y enfatizamos
            revisar continuamente esta página para asegurarse que está de
            acuerdo con dichos cambios.
          </p>
          <h2 className={classes.title}>Información que es recogida</h2>
          <p className={classes.description}>
            Nuestra apliación móvil y sitio web podrá recoger información
            personal por ejemplo: Nombre,&nbsp; información de contacto
            como&nbsp; su dirección de correo electrónica e información
            demográfica. Así mismo cuando sea necesario podrá ser requerida
            información específica como ubicación actual, que sirven
            particularmente para brindar el servicio de taxi requerido por el
            usuario y el conductor asignado.
          </p>
          <h2 className={classes.title}>Uso de la información recogida</h2>
          <p className={classes.description}>
            Nuestro sitio web emplea la información con el fin de proporcionar
            el mejor servicio posible, particularmente para mantener un registro
            de usuarios, de pedidos en caso que aplique, y mejorar nuestros
            productos y servicios. Es posible que sean enviados correos
            electrónicos periódicamente a través de nuestro sitio con ofertas
            especiales, nuevos productos y otra información publicitaria que
            consideremos relevante para usted o que pueda brindarle algún
            beneficio, estos correos electrónicos serán enviados a la dirección
            que usted proporcione y podrán ser cancelados en cualquier momento.
          </p>
          <p className={classes.description}>
            Nuestra aplicación móvil y sitio web recoge los datos de
            localización, para habilitar los servicios de ubicación de usuario,
            ubicación del conductor, tiempo estimado de reccorrido y tiempo
            estimado de llegada por el conductor, incluso cuando la aplicación
            móvil esté cerrada o no esté en uso.
          </p>
          <p className={classes.description}>
            TU TAXI SEGURO está altamente comprometido para cumplir con el
            compromiso de mantener su información segura. Usamos los sistemas
            más avanzados y los actualizamos constantemente para asegurarnos que
            no exista ningún acceso no autorizado.
          </p>
          <h2 className={classes.title}>Cookies</h2>
          <p className={classes.description}>
            Una cookie se refiere a un fichero que es enviado con la finalidad
            de solicitar permiso para almacenarse en su ordenador, al aceptar
            dicho fichero se crea y la cookie sirve entonces para tener
            información respecto al tráfico web, y también facilita las futuras
            visitas a una web recurrente. Otra función que tienen las cookies es
            que con ellas las web pueden reconocerte individualmente y por tanto
            brindarte el mejor servicio personalizado de su web.
          </p>
          <p className={classes.description}>
            Nuestro sitio web emplea las cookies para poder identificar las
            páginas que son visitadas y su frecuencia. Esta información es
            empleada únicamente para análisis estadístico y después la
            información se elimina de forma permanente. Usted puede eliminar las
            cookies en cualquier momento desde su ordenador. Sin embargo las
            cookies ayudan a proporcionar un mejor servicio de los sitios web,
            estás no dan acceso a información de su ordenador ni de usted, a
            menos de que usted así lo quiera y la proporcione directamente .
            Usted puede aceptar o negar el uso de cookies, sin embargo la
            mayoría de navegadores aceptan cookies automáticamente pues sirve
            para tener un mejor servicio web. También usted puede cambiar la
            configuración de su ordenador para declinar las cookies. Si se
            declinan es posible que no pueda utilizar algunos de nuestros
            servicios.
          </p>
          <h2 className={classes.title}>Enlaces a Terceros</h2>
          <p className={classes.description}>
            Nuestra aplicación y sitio web pudieran contener enlaces a otros
            sitios que pudieran ser de su interés. Una vez que usted de clic en
            estos enlaces y abandone nuestra página o aplicación, ya no tenemos
            control sobre al sitio al que es redirigido y por lo tanto no somos
            responsables de los términos o privacidad ni de la protección de sus
            datos en esos otros sitios terceros. Dichos sitios están sujetos a
            sus propias políticas de privacidad por lo cual es recomendable que
            los consulte para confirmar que usted está de acuerdo con estas.
          </p>
          <h2 className={classes.title}>Control de su información personal</h2>
          <p className={classes.description}>
            En cualquier momento usted puede restringir la recopilación o el uso
            de la información personal que es proporcionada a nuestra aplicación
            y sitio web.&nbsp; Cada vez que se le solicite rellenar un
            formulario, como el de registro de usuario, usted está aceptando el
            envío de notificaciones por mail y/o notificaciones móviles con el
            que comunicamos el estado de su solicitud de servicio.
          </p>
          <p className={classes.description}>
            Esta compañía no venderá, cederá ni distribuirá la información
            personal que es recopilada sin su consentimiento, salvo que sea
            requerido por un juez con un orden judicial.
          </p>
          <p className={classes.description}>
            TU TAXI SEGURO Se reserva el derecho de cambiar los términos de la
            presente Política de Privacidad en cualquier momento.
          </p>
          <p className={classes.description}>&nbsp;</p>
        </div>
      </div>

      <Footer />
    </div>
  );
}
