import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { language } from "config";
import { FirebaseContext } from "common";

const Withdraws = () => {
  const { api } = useContext(FirebaseContext);
  const { completeWithdraw } = api;
  const dispatch = useDispatch();
  const columns = [
    { title: "ID", field: "id", editable: "never" },
    { title: language.requestDate, field: "date", editable: "never" },
    { title: language.driver_name, field: "name", editable: "never" },
    { title: language.amount, field: "amount", editable: "never" },
    {
      title: language.processed,
      field: "processed",
      type: "boolean",
      editable: "never",
    },
    { title: language.processDate, field: "procesDate", editable: "never" },
    { title: language.bankName, field: "bankName", editable: "never" },
    { title: language.bankCode, field: "bankCode", editable: "never" },
    { title: language.bankAccount, field: "bankAccount", editable: "never" },
  ];
  const [data, setData] = useState([]);
  const withdrawdata = useSelector((state) => state.withdrawdata);

  useEffect(() => {
    if (withdrawdata.withdraws) {
      setData(withdrawdata.withdraws);
    } else {
      setData([]);
    }
  }, [withdrawdata.withdraws]);

  return withdrawdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={language.booking_title}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
      }}
      actions={[
        (rowData) => ({
          icon: "check",
          tooltip: language.process_withdraw,
          disabled: rowData.processed,
          onClick: (event, rowData) => {
            dispatch(completeWithdraw(rowData));
          },
        }),
      ]}
      localization={{
        grouping: {
          placeholder: "Arrastra la columna que quieres agrupar",
          groupedBy: "Agrupado por",
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "registros",
          labelRowsPerPage: "Registros por página",
          firstAriaLabel: "Primera",
          firstTooltip: "Primera",
          previousAriaLabel: "Anterior",
          previousTooltip: "Anterior",
          nextAriaLabel: "Siguiente",
          nextTooltip: "Siguiente",
          lastAriaLabel: "Última",
          lastTooltip: "Última",
        },
        toolbar: {
          nRowsSelected: "{0} seleccionados",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
          exportTitle: "Exportar",
          exportAriaLabel: "Exportar",
          exportName: "Exportar",
        },
        header: {
          actions: "Acciones",
        },
        body: {
          emptyDataSourceMessage: "No hay registros disponibles",
          filterRow: {
            filterTooltip: "Filtrar",
          },
          editRow: {
            addTooltip: "Agregar",
            editTooltip: "Editar",
            deleteTooltip: "Eliminar",
            deleteText: "¿Estás seguro de eliminar este registro?",
            cancelTooltip: "Cancelar",
            saveTooltip: "Guardar",
          },
        },
      }}
    />
  );
};

export default Withdraws;
