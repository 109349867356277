import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { features, language } from "config";
import { FirebaseContext } from "common";

export default function Notifications() {
  const { api } = useContext(FirebaseContext);
  const { sendNotification, editNotifications } = api;

  const columns = [
    {
      title: language.device_type,
      field: "devicetype",
      lookup: { All: "All", ANDROID: "Android", IOS: "iOS" },
    },
    {
      title: language.user_type,
      field: "usertype",
      lookup: { rider: language.rider, driver: language.driver },
    },
    { title: language.title, field: "title" },
    { title: language.body, field: "body" },
  ];

  const [data, setData] = useState([]);
  const notificationdata = useSelector((state) => state.notificationdata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationdata.notifications) {
      setData(notificationdata.notifications);
    } else {
      setData([]);
    }
  }, [notificationdata.notifications]);

  return notificationdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={language.push_notification_title}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.push(newData);
              features.AllowCriticalEditsAdmin
                ? dispatch(sendNotification(newData))
                : alert(language.demo_mode);
              dispatch(editNotifications(newData, "Add"));
            }, 600);
          }),

        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch(editNotifications(newData, "Update"));
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch(editNotifications(oldData, "Delete"));
            }, 600);
          }),
      }}
      localization={{
        grouping: {
          placeholder: "Arrastra la columna que quieres agrupar",
          groupedBy: "Agrupado por",
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "registros",
          labelRowsPerPage: "Registros por página",
          firstAriaLabel: "Primera",
          firstTooltip: "Primera",
          previousAriaLabel: "Anterior",
          previousTooltip: "Anterior",
          nextAriaLabel: "Siguiente",
          nextTooltip: "Siguiente",
          lastAriaLabel: "Última",
          lastTooltip: "Última",
        },
        toolbar: {
          nRowsSelected: "{0} seleccionados",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
          exportTitle: "Exportar",
          exportAriaLabel: "Exportar",
          exportName: "Exportar",
        },
        header: {
          actions: "Acciones",
        },
        body: {
          emptyDataSourceMessage: "No hay registros disponibles",
          filterRow: {
            filterTooltip: "Filtrar",
          },
          editRow: {
            addTooltip: "Agregar",
            editTooltip: "Editar",
            deleteTooltip: "Eliminar",
            deleteText: "¿Estás seguro de eliminar este registro?",
            cancelTooltip: "Cancelar",
            saveTooltip: "Guardar",
          },
        },
      }}
    />
  );
}
