export const default_country_code = {
  code: "EC",
  label: "Ecuador",
  phone: "593",
  suggested: true,
};

export const countries = [
  { code: "EC", label: "Ecuador", phone: "593", suggested: true },
];
