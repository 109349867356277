import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { language } from "config";

export default function Earningreports() {
  const columns = [
    { title: language.year, field: "year" },
    { title: language.months, field: "monthsName" },
    { title: language.Gross_trip_cost, field: "tripCost" },
    { title: language.trip_cost_driver_share, field: "rideCost" },
    { title: language.convenience_fee, field: "convenienceFee" },

    { title: language.Discounts, field: "discountAmount" },
    {
      title: language.Profit,
      field: "profit",
      render: (rowData) => rowData.convenienceFee - rowData.discountAmount,
      editable: "never",
    },
  ];

  const [data, setData] = useState([]);
  const earningreportsdata = useSelector((state) => state.earningreportsdata);

  useEffect(() => {
    if (earningreportsdata.Earningreportss) {
      setData(earningreportsdata.Earningreportss);
    }
  }, [earningreportsdata.Earningreportss]);

  return earningreportsdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={language.earning_reports}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
      }}
      localization={{
        grouping: {
          placeholder: "Arrastra la columna que quieres agrupar",
          groupedBy: "Agrupado por",
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "registros",
          labelRowsPerPage: "Registros por página",
          firstAriaLabel: "Primera",
          firstTooltip: "Primera",
          previousAriaLabel: "Anterior",
          previousTooltip: "Anterior",
          nextAriaLabel: "Siguiente",
          nextTooltip: "Siguiente",
          lastAriaLabel: "Última",
          lastTooltip: "Última",
        },
        toolbar: {
          nRowsSelected: "{0} seleccionados",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
          exportTitle: "Exportar",
          exportAriaLabel: "Exportar",
          exportName: "Exportar",
        },
        header: {
          actions: "Acciones",
        },
        body: {
          emptyDataSourceMessage: "No hay registros disponibles",
          filterRow: {
            filterTooltip: "Filtrar",
          },
          editRow: {
            addTooltip: "Agregar",
            editTooltip: "Editar",
            deleteTooltip: "Eliminar",
            deleteText: "¿Estás seguro de eliminar este registro?",
            cancelTooltip: "Cancelar",
            saveTooltip: "Guardar",
          },
        },
      }}
    />
  );
}
