import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { language } from "config";

export default function DriverEarning() {
  const columns = [
    { title: language.year, field: "year" },
    { title: language.months, field: "monthsName" },
    { title: language.driver_name, field: "driverName" },
    { title: language.vehicle_reg_no, field: "driverVehicleNo" },
    { title: language.earning_amount, field: "driverShare" },
  ];

  const [data, setData] = useState([]);
  const driverearningdata = useSelector((state) => state.driverearningdata);

  useEffect(() => {
    if (driverearningdata.driverearnings) {
      setData(driverearningdata.driverearnings);
    }
  }, [driverearningdata.driverearnings]);

  return driverearningdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={language.driver_earning}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        grouping: true,
      }}
      localization={{
        grouping: {
          placeholder: "Arrastra la columna que quieres agrupar",
          groupedBy: "Agrupado por",
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "registros",
          labelRowsPerPage: "Registros por página",
          firstAriaLabel: "Primera",
          firstTooltip: "Primera",
          previousAriaLabel: "Anterior",
          previousTooltip: "Anterior",
          nextAriaLabel: "Siguiente",
          nextTooltip: "Siguiente",
          lastAriaLabel: "Última",
          lastTooltip: "Última",
        },
        toolbar: {
          nRowsSelected: "{0} seleccionados",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
          exportTitle: "Exportar",
          exportAriaLabel: "Exportar",
          exportName: "Exportar",
        },
        header: {
          actions: "Acciones",
        },
        body: {
          emptyDataSourceMessage: "No hay registros disponibles",
          filterRow: {
            filterTooltip: "Filtrar",
          },
          editRow: {
            addTooltip: "Agregar",
            editTooltip: "Editar",
            deleteTooltip: "Eliminar",
            deleteText: "¿Estás seguro de eliminar este registro?",
            cancelTooltip: "Cancelar",
            saveTooltip: "Guardar",
          },
        },
      }}
    />
  );
}
